<script setup lang="ts">
import formatDate from '../../helpers/formatDate';
import CustomerNameBadge from '~/components/Customers/CustomerNameBadge.vue';
import type {Note} from '~/models/note';
import {GMButton, GMIcon, GMInputGroup, GMModal, GMTextarea,} from '@gm/components'
import CustomerSelect from '~/components/Customers/CustomerSelect.vue';
import Printer from '~/components/icon/printer.vue';

const NOTE_TITLE_MAX_LENGTH = 850
const NOTE_BODY_MAX_LENGTH = 2000

const props = withDefaults(defineProps<{
  show?: boolean;
  note: Note;
}>(), {
  show: false
})

const tempNote = ref<Note>({ ...props.note })
const editNote = ref(!tempNote.value.LocalId)

watch(props.note, (note) => {
  tempNote.value = { ...note }
})

const tempNoteBodyModel = computed({
  get() {
    return tempNote.value.Body
  },
  set(value: string) {
    if (value.length > NOTE_BODY_MAX_LENGTH) {
      tempNote.value.Body = value.substring(0, NOTE_BODY_MAX_LENGTH)
    } else {
      tempNote.value.Body = value
    }
  }
})

const tempNoteTitleModel = computed({
  get() {
    return tempNote.value.Title
  },
  set(value: string) {
    if (value.length > NOTE_TITLE_MAX_LENGTH) {
      tempNote.value.Title = value.substring(0, NOTE_TITLE_MAX_LENGTH)
    } else {
      tempNote.value.Title = value
    }
  }
})

const onEditClick = () => {
  editNote.value = true
}

const onCancelClick = () => {
  editNote.value = false
  tempNote.value = { ...props.note }
}

const emit = defineEmits(['delete','save','navigate','close'])

const onDeleteClick = () => {
  emit('delete', tempNote.value)
  emit('close')
}

const onSave = () => {
  editNote.value = false
  emit('save', tempNote.value)
}

const onNavigate = () => {
  emit('navigate')
  emit('close')
}

</script>
<template>
  <GMModal show @close="$emit('close')">
    <template #header>
      <div class="flex w-full mr-3 items-center">
        <div v-if="!editNote">
          <h1 class="text-2xl">
            {{ tempNote.Title }}
            <GMButton
              icon-only
              icon-before="pencil"
              @click="onEditClick"
              class="mr-2"
            ></GMButton>
          </h1>
          <div class="text-sm text-gray-500">
            {{
              formatDate(tempNote.CreatedAt, undefined, { dateStyle: 'medium' })
            }}
          </div>
        </div>
        <GMInputGroup
          v-else
          v-model="tempNoteTitleModel"
          class="w-full"
          placeholder="Tittel"
        />
      </div>
    </template>
    <template #default>
      <div class="w-[80vw] max-w-full">
        <div v-if="!editNote" class="whitespace-pre-line">{{ tempNote.Body }}</div>
        <template v-else>
          <GMTextarea
            v-model="tempNoteBodyModel"
            class="w-full h-96"
            rows="15"
            auto-resize
          ></GMTextarea>
        </template>

        <section class="flex gap-x-2 items-baseline">
          <div v-if="editNote && !tempNote.ContractId && !tempNote.PropertyId" class="mt-4">
            Legg til notatet på forretningsforbindelse
            <span class="text-sm text-gray-500">Valgfritt</span>
            <CustomerSelect v-model="tempNote.ForestOwnerId" />
          </div>
          <CustomerNameBadge
            v-else-if="tempNote.ForestOwnerId"
            :customer-id="tempNote.ForestOwnerId"
            class="mt-6"
          />
          <div v-if="tempNote.PropertyId && tempNote.PropertyName">
            <NuxtLink v-if="!editNote" :to="`/customers/${tempNote.ForestOwnerId}/${tempNote.PropertyId}`" @click.native="onNavigate">
              <GMButton class="mt-4" icon-after="chevron-right">{{ tempNote.PropertyName }}</GMButton>
            </NuxtLink>
            <div v-else class="text-sm bg-gray-200 dark:bg-gray-800 rounded-full inline-flex items-center px-4 py-0">{{ tempNote.PropertyName }}</div>
          </div>
          <div v-if="tempNote.ContractId">
            <NuxtLink v-if="!editNote" :to="`/customers/${tempNote.ForestOwnerId}/${tempNote.PropertyId}/${tempNote.ContractId}`" @click.native="onNavigate">
              <GMButton class="mt-4" icon-after="chevron-right">
                <template v-if="tempNote.ContractVsysId">
                  {{ tempNote.ContractVsysId }}
                </template>
                <template v-else-if="tempNote.ContractNumber">
                  {{ tempNote.ContractNumber }}
                </template>
              </GMButton>
            </NuxtLink>
            <div v-else class="text-sm bg-gray-200 dark:bg-gray-800 rounded-full inline-flex items-center px-4 py-0">
              <template v-if="tempNote.ContractVsysId">
                {{ tempNote.ContractVsysId }}
              </template>
              <template v-else-if="tempNote.ContractNumber">
                {{ tempNote.ContractNumber }}
              </template>
            </div>
          </div>
        </section>
      </div>
    </template>
    <template #footer>
      <div v-if="!editNote" class="flex flex-wrap gap-2">
        <GMButton @click="$emit('pin', note)">
          <GMIcon
            :name="note.Pinned ? 'star' : 'star-outline'"
            class="inline w-5 h-5 mr-1"
            :class="{
              'text-yellow-400': note.Pinned,
              'text-blue-gray-400': !note.Pinned
            }"
          />
          Favoritt
        </GMButton>
<!--        <GMButton @click="$emit('share', note)">
          <Share class="inline w-5 h-5 mr-1 text-blue-gray-400" />
          Del
        </GMButton>-->
        <GMButton @click="$emit('print', note)">
          <Printer class="inline w-5 h-5 mr-1 text-blue-gray-400" />
          Skriv ut
        </GMButton>
        <GMButton @click="$emit('download', note)">
          <GMIcon
            name="download"
            class="inline w-5 h-5 mr-1 text-blue-gray-400"
          />
          Last ned
        </GMButton>
        <GMButton
          v-if="tempNote.LocalId"
          desctructive
          @click="onDeleteClick"
        >
          <GMIcon
            name="trash"
            class="inline w-5 h-5 mr-1 text-red-400 dark:text-red-600"
          />
          Slett
        </GMButton>
      </div>
      <div v-else class="flex justify-between gap-2">
        <GMButton
          v-if="tempNote.LocalId"
          class="flex-1"
          desctructive
          @click="onDeleteClick"
        >
          <GMIcon
            name="trash"
            class="inline w-5 h-5 mr-1 text-red-400 dark:text-red-600"
          />
          Slett
        </GMButton>
        <GMButton icon-before="x" class="flex-1" tertiary @click="onCancelClick"
          >Avbryt
        </GMButton>
        <GMButton icon-before="check" class="flex-1" primary @click="onSave"
          >Lagre
        </GMButton>
      </div>
    </template>
  </GMModal>
</template>
