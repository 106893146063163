<template>
  <div
      :id="`listboxOption_${customer.Id}`"
      ref="elementRef"
      class="transition duration-100 flex flex-row justify-between items-center border-b dark:border-gray-700 px-4 py-5 sm:py-3 hover:bg-gray-200 dark:hover:bg-gray-700 dark:text-gray-50 cursor-pointer"
      :class="{
      'bg-gray-200 dark:bg-gray-700 ring-2 ring-inset': isActive
    }"
      role="option"
      :aria-selected="isActive"
      @click="onCustomerClick(customer.Id)"
  >
    <GMCustomerName
        class="flex-1 text-gray-700 dark:text-gray-300 capitalize text-base"
        :name="customer.Name.toLowerCase()"
    />
    <GMIcon
        v-if="environmentalAgreementSigned"
        name="badge-check"
        class="w-6 h-6 text-green-600 dark:text-green-400 mr-2"
    />
    <UserCheck v-if="isUserVerified" class="w-6 h-6 text-blue-600 dark:text-blue-400 mr-2"/>
    <GMOrb class="mr-2 bg-gray-200 dark:bg-gray-800">
      {{ customer.Properties.length }}
    </GMOrb>
    <GMIconToggle :model-value="isFavorite" icon-active="star"
                  icon-inactive="star-outline"
                  class="relative w-5 sm:w-8 h-5 sm:h-8"
                  active-class="text-yellow-400"
                  in-active-class="text-gray-400"
                  @update:model-value="toggleFavorite"
                  @click.stop
    >
      {{
        isFavorite
          ? $t('FAVOURITE_TRUE_REMOVE_DESC')
          : $t('FAVOURITE_FALSE_ADD_DESC')
      }}
    </GMIconToggle>
  </div>
</template>
<script lang="ts">

import { useCustomersStore } from '~/use/customers/store'
import { ENVIRONMENTAL_AGREEMENT_STATES_SIGNED } from '~/use/environmentalAgreements/constants'
import UserCheck from '~/components/icon/user-check.vue'
import {GMCustomerName, GMIcon, GMIconToggle, GMOrb} from '@gm/components';
import type {PropType} from "@vue/runtime-core";
import type {Customer} from '~/models/customers';
import {featureEnabled} from '~/helpers/features';

export default defineComponent({
  components: {
    UserCheck,
    GMCustomerName,
    GMIconToggle,
    GMOrb,
    GMIcon
  },
  props: {
    navigateOnClick: {
      type: Boolean,
      default: true
    },
    activeItem: {
      type: Object as PropType<Customer>,
      default: {} as Customer
    },
    customer: {
      type: Object as PropType<Customer>,
      default: {} as Customer
    },
    isFirst: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    },
    listboxId: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const router = useRouter()
    const parent = getCurrentInstance()?.parent
    const { favoriteIds, addFavorite, removeFavorite } = useCustomersStore()
    const elementRef = ref()

    const onCustomerClick = (id: string): void => {
      if (props.navigateOnClick) {
        const path =
          props.customer.Properties.length === 1
            ? `/customers/${id}/${props.customer.Properties[0].Id}`
            : `/customers/${id}`
        router.push(path)
      } else {
        emit('select', props.customer)
      }
    }

    const environmentalAgreementSigned = computed<boolean>(
      () =>
        props.customer &&
        props.customer.EnvironmentalAgreement &&
        props.customer.EnvironmentalAgreement.Status ===
          ENVIRONMENTAL_AGREEMENT_STATES_SIGNED
    )

    const isActive = computed<boolean>(
      () => props.activeItem === props.customer
    )

    const isFavorite = computed(() =>
      favoriteIds.value.includes(props.customer.Id)
    )

    const toggleFavorite = (e: boolean) => {
      if (e) {
        addFavorite(props.customer.Id)
      } else {
        removeFavorite(props.customer.Id)
      }
    }

    const runtimeConfig = useRuntimeConfig()
    const featureSignWithBankIDEnabled = featureEnabled(runtimeConfig.public.FEATURE_SIGN_WITH_BANKID)
    const isUserVerified = computed(() => featureSignWithBankIDEnabled && props.customer.IsCustomerVerified)

    watch(isActive, () => {
      if (isActive.value) {
        const scrollContainerElement = parent?.vnode?.el?.closest(
          `#${props.listboxId}`
        ) as HTMLElement
        const element = elementRef.value as HTMLElement

        if (scrollContainerElement && element) {
          const height = scrollContainerElement.getBoundingClientRect()?.height
          const elementTop = element.getBoundingClientRect().top
          const elementBottom = element.getBoundingClientRect().bottom

          // If the element bottom is larger than height we need to scroll down into view
          // If the element top is negative we need to scroll up into view
          if (elementBottom > height) {
            element.scrollIntoView(false)
          } else if (elementTop < 0) {
            element.scrollIntoView()
          }
        }
      }
    })

    return {
      environmentalAgreementSigned,
      onCustomerClick,
      isActive,
      isFavorite,
      toggleFavorite,
      isUserVerified,
      elementRef,
    }
  }
})
</script>
