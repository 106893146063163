<template>
  <div v-if="name" class="text-sm bg-blue-100 dark:bg-gray-800 rounded-full inline-flex items-center px-4 py-0">
    {{ name }} <GMButton size="small" v-if="showCancel" icon-only icon-before="x" tertiary class="text-current" @click="$emit('cancel')"/>
  </div>
</template>

<script lang="ts">
import type {Customer} from '~/models/customers'
import { customersDb } from '~/helpers/indexDB'
import { GMButton, GMOrb } from '@gm/components'

export default defineComponent({
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: false,
    },
    customerId: {
      type: String,
      required: false,
    },
    customerName: {
      type: String,
      required: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    GMOrb,
    GMButton,
  },
  setup(props: {
    customer?: Customer,
    customerId?: string,
    customerName?: string,
  }) {
    const dbCustomer = ref<Customer>(null)
    watch(() => props.customerId, async id => {
      if (id) {
        dbCustomer.value = await customersDb.getById(id)
      } else {
        dbCustomer.value = null
      }
    }, { immediate: true })

    const name = computed(() => {
      if (props.customer) {
        return props.customer.Name
      } else if (props.customerName) {
        return props.customerName
      } else if (dbCustomer.value) {
        return dbCustomer.value.Name
      }
      return ''
    })

    return {
      name
    }
  }
})
</script>
