<template>
  <div>
    <Search
      v-if="!selectedCustomer"
      :navigate-on-click="false"
      @select="onCustomerSelect"
      direction="top"
      inline
      variant="secondary"
    />
    <CustomerNameBadge
      v-else
      :customer="selectedCustomer"
      show-cancel
      @cancel="model = undefined"
    />
  </div>
</template>

<script lang="ts">
import { useCustomersDb } from '~/use/indexedDb/customersDb'
import { GMIcon } from '@gm/components'
import type {Customer} from '~/models/customers'
import Search from '~/components/ui/Search.vue'
import CustomerNameBadge from '~/components/Customers/CustomerNameBadge.vue'

export default defineComponent({
  components: { CustomerNameBadge, GMIcon, Search },
  props: {
    modelValue: {
      type: String
    },
    id: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { customersDb } = useCustomersDb()
    const inputModel = ref('')
    const model = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:modelValue', value)
      }
    })
    const selectedCustomer = ref<Customer | null>(null)
    watch(
      () => model.value,
      async (value) => {
        if (value) {
          selectedCustomer.value = await customersDb.getById(value as string)
        } else {
          selectedCustomer.value = null
        }
      },
      { immediate: true }
    )
    const onCustomerSelect = (customer: Customer) => {
      model.value = customer.Id
    }

    return {
      inputModel,
      model,
      onCustomerSelect,
      selectedCustomer
    }
  }
})
</script>
