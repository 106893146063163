// Suggestion for model
export interface Note {
  LocalId?: string
  Id?: string
  Title?: string
  Body?: string
  ForestOwnerId?: string
  PropertyId?: string
  PropertyName?: string
  ContractId?: string
  ContractNumber?: string
  ContractVsysId?: string
  Pinned?: boolean
  CreatedAt?: string
  CreatedBy?: string
  ModifiedAt?: string
  ModifiedBy?: string
  DeletedAt?: string
  SyncStatus?: number
}

export const SyncStatus = {
  Synced: 0,
  CreatedLocal: 1,
  UpdatedLocal: 2,
  DeletedLocal: 3,
}
