<template>
  <div>
    <header class="flex justify-between">
      <div class="flex">
        <h1 class="text-2xl">Notater</h1>
        <GMButton icon-before="plus" @click="onNewNoteClick"
          >Nytt notat</GMButton
        >
      </div>
      <div class="flex divide-x gap-4" v-if="showSortControl">
        <span
          ><SortAscending class="inline w-5 h-5 mr-1" /> Dato opprettet</span
        >
        <GMIcon name="chevron-down" class="w-7 h-7" />
      </div>
    </header>
    <article>
      <section
        v-if="showSearchbar"
        class="mt-4 flex items-center border border-gray-400 dark:border-gray-700 rounded"
      >
        <GMIcon name="search" class="absolute left-10 w-5 h-5 text-gray-400" />
        <GMInput
          v-model="searchTerm"
          placeholder="Søk i notatene"
          class="w-full pl-14 h-14 border"
        />
      </section>
      <GMLoader v-if="isLoading" />
      <section v-else class="mt-6 flex flex-wrap gap-4">
        <NoteThumbnail
          v-for="note in filteredNotes"
          :key="note.LocalId"
          :note="note"
          @click="openNote(note)"
          @keydown.space.enter.prevent="openNote(note)"
        />
      </section>
    </article>
    <NoteModal
      v-if="selectedNote"
      :note="selectedNote"
      @close="selectedNote = null"
      @save="onSaveNote"
      @delete="onDeleteNote"
      @pin="onPinNote"
      @share="onShareNote"
      @print="onPrintNote"
      @download="onDownloadNote"
      @navigate="onNavigate"
    />

    <NotePrintPage
      v-if="selectedNote"
      :note="selectedNote"
      ref="printPage"
      class="invisible"
    />
  </div>
</template>

<script lang="ts">
import { useNotes } from '~/use/notes/notes'
import { GMButton, GMIcon, GMInput, GMLoader } from '@gm/components'
import NoteThumbnail from '~/components/Notes/NoteThumbnail.vue'
import type {Note} from '~/models/note'
import NoteModal from '~/components/Notes/NoteModal.vue'
import { useNotesDb } from '~/use/indexedDb/notesDb'
import SortAscending from '~/components/icon/sort-ascending.vue'
import NotePrintPage from '~/components/Notes/NotePrintPage.vue'
import { getForestOwnerNameById } from '~/helpers/contractHelper'
import type {NoteListContext} from '~/components/Notes/noteListContext'
import { debounce } from 'lodash'
import type {ComponentInstance} from "@vue/devtools-api";
import type {PropType} from "@vue/runtime-core";

function notesComparator(a: Note, b: Note) {
  // sort pinned first, then by created date descending
  if (a.Pinned && !b.Pinned) {
    return -1
  }
  if (!a.Pinned && b.Pinned) {
    return 1
  }
  return b.CreatedAt.localeCompare(a.CreatedAt)
}

export default defineComponent({
  components: {
    NotePrintPage,
    SortAscending,
    NoteModal,
    NoteThumbnail,
    GMIcon,
    GMInput,
    GMButton,
    GMLoader
  },
  props: {
    showSearchbar: {
      type: Boolean,
      default: false
    },
    showSortControl: {
      type: Boolean,
      default: false
    },
    context: {
      type: Object as PropType<NoteListContext>,
      default: () => undefined
    }
  },
  setup(props) {
    const {
      isNotesOpen,
      deleteNote,
      saveNote,
      notesChangesFromServer,
      closeNotes
    } = useNotes()
    const searchTerm = ref('')
    const selectedNote = ref<Note | null>(null)
    const { notesDb } = useNotesDb()
    const allNotes = ref<Note[]>([])
    const printPage = ref<ComponentInstance>(null)
    const isLoading = ref<Boolean>(false)

    async function loadNotes() {
      isLoading.value = true
      let promise: Promise<Note[]>
      switch (props.context?.type) {
        case 'contract':
          promise = notesDb.getByContractId(props.context.contractId)
          break
        case 'forest-owner':
          promise = notesDb.getByForestOwnerId(props.context.forestOwnerId)
          break
        case 'property':
          promise = notesDb.getByPropertyId(props.context.propertyId)
          break
        default:
          promise = notesDb.getAll()
          break
      }
      await promise
        .then((notes) => {
          allNotes.value = notes.sort(notesComparator)
        })
        .finally(() => {
          isLoading.value = false
        })
      if (selectedNote.value) {
        selectedNote.value = allNotes.value.find(
          (n) => n.LocalId === selectedNote.value?.LocalId
        )
      }
    }

    onMounted(() => {
      isLoading.value = true
      loadNotes()
    })
    watch(notesChangesFromServer, loadNotes)
    watch(() => props.context, debounce(loadNotes, 300))

    const filteredNotes = computed(() => {
      if (searchTerm.value) {
        const lowerCaseSearchTerm = searchTerm.value.toLowerCase()
        return allNotes.value.filter((n) => {
          return (
            n.Title?.toLowerCase()?.includes(lowerCaseSearchTerm) ||
            n.Body?.toLowerCase()?.includes(lowerCaseSearchTerm)
          )
        })
      }
      return allNotes.value
    })

    const openNote = (note: Note) => {
      selectedNote.value = note
    }

    const onSaveNote = (note: Note) => {
      selectedNote.value = note
      saveNote(note).then(() => {
        loadNotes()
      })
    }

    const onPinNote = (note: Note) => {
      note.Pinned = !note.Pinned
      saveNote(note).then(() => {
        selectedNote.value = { ...note }
        loadNotes()
      })
    }

    const onDeleteNote = (note: Note) => {
      deleteNote(note).then(() => {
        loadNotes()
      })
    }

    const onNewNoteClick = () => {
      selectedNote.value = {
        Title: '',
        Body: '',
        CreatedAt: new Date().toISOString(),
        Pinned: false,
        CreatedBy: '',
        ForestOwnerId: props.context?.forestOwnerId,
        PropertyId: props.context?.propertyId,
        ContractId: props.context?.contractId
      }
    }

    const onShareNote = (note: Note) => {
      console.log('todo: implement share note', note)
    }
    const onPrintNote = async () => {
      const printWindow = window.open('', 'PRINT', 'height=400,width=600')
      if (printWindow && printPage.value) {
        printWindow.document.write(printPage.value.$el.innerHTML)
        printWindow.document.close()
        printWindow.print()
        printWindow.onafterprint = () => {
          printWindow.close()
        }
      }
    }
    const onDownloadNote = async (note: Note) => {
      let forestOwnerName = ''
      if (note.ForestOwnerId) {
        forestOwnerName = await getForestOwnerNameById(note.ForestOwnerId)
      }
      let text = `${note.Body}`
      if (forestOwnerName) {
        text = `${text}\n\n--\n${forestOwnerName}`
      }
      const filename = `${note.Title}.txt`
      const blob = new Blob([text], { type: 'text/plain' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
    }

    const onNavigate = () => {
      closeNotes()
    }

    return {
      isNotesOpen,
      searchTerm,
      filteredNotes,
      openNote,
      selectedNote,
      onSaveNote,
      onPinNote,
      onNewNoteClick,
      onDeleteNote,
      onShareNote,
      onPrintNote,
      onDownloadNote,
      printPage,
      onNavigate,
      isLoading,
    }
  }
})
</script>
