import { useNotesDb } from '~/use/indexedDb/notesDb'
import {type Note, SyncStatus } from '~/models/note'
import { generateUUID } from '~/helpers/util'
import { useAxiosClient } from '~/use/axios/client'
import {
  type ActionModelsLibrarySBLNoteSblAppListNoteSblAppNoteSblAppItem,
  NotesAndFilesApi
} from '~/gen/openapi/sblService'
import type {AxiosInstance} from 'axios'
import { useUserStore } from '~/use/user/store'
import { notNil } from '~/helpers/notNil'
import {notify} from "@gm/components";

function crmNoteToNote(
  crmNote: ActionModelsLibrarySBLNoteSblAppListNoteSblAppNoteSblAppItem
): Note {
  return {
    Id: crmNote.NoteSblAppId,
    LocalId: undefined,
    Title: crmNote.Name,
    Body: crmNote.Description,
    CreatedAt: crmNote.DateCreated,
    ModifiedAt: crmNote.DateModified,
    SyncStatus: undefined,
    PropertyId: crmNote.PropertyId,
    PropertyName: crmNote.PropertyName,
    ForestOwnerId: crmNote.ForestOwnerAccountId,
    Pinned: crmNote.IsFavorite,
    ContractId: crmNote.ForestOperationContractId,
    ContractNumber: crmNote.ForestOperationContractNo,
    ContractVsysId: crmNote.ForestOperationVsysNo,
  }
}

function noteToCrmNote(
  note: Note,
  userId: string
): ActionModelsLibrarySBLNoteSblAppListNoteSblAppNoteSblAppItem {
  return {
    NoteSblAppId: note.Id,
    Name: note.Title,
    Description: note.Body,
    DateCreated: note.CreatedAt,
    DateModified: note.ModifiedAt,
    IsFavorite: note.Pinned,
    PropertyId: note.PropertyId ?? null,
    ForestOwnerAccountId: note.ForestOwnerId ?? null,
    ForestOperationContractId: note.ContractId ?? null,
    ForestManagerUserId: userId,
    ForestOperationContractNo: note.ContractNumber ?? null,
    ForestOperationVsysNo: note.ContractVsysId ?? null,
    PropertyName: note.PropertyName ?? null,
  }
}

const state = reactive({
  isNotesOpen: false,
  notesChangesFromServer: 0
})

const isNotesOpen = computed(() => state.isNotesOpen)
const closeNotes = () => {
  state.isNotesOpen = false
}
const openNotes = () => {
  state.isNotesOpen = true
}
const toggleNotes = () => {
  state.isNotesOpen = !state.isNotesOpen
}

const notesChangesFromServer = computed(() => state.notesChangesFromServer)

const axiosWrapper = (axios: AxiosInstance) => {
  const { currentUser } = useUserStore()
  const notesApi = new NotesAndFilesApi(null, '', axios)

  const saveNote = async (note: Note): Promise<void> => {
    const { notesDb } = useNotesDb()
    if (!note.LocalId) {
      note.LocalId = generateUUID()
      note.CreatedAt = new Date().toISOString()
    } else {
      // saveNote might be called with stale data, e.g. updating a newly created note.
      // try to find the note in the db, check if it has been synced with server, and append the id if it has to avoid dupes.
      const dbNote = await notesDb.getById(note.LocalId)
      if (dbNote && dbNote.Id) {
        note.Id = dbNote.Id
      }
    }
    if (note.Id) {
      note.SyncStatus = SyncStatus.UpdatedLocal
    } else {
      note.SyncStatus = SyncStatus.CreatedLocal
    }
    note.ModifiedAt = new Date().toISOString()
    await notesDb.put(note)
    try {
      await syncNote(note)
    } catch (error) {
      console.error('saveNote: syncNote', error)
    }
  }

  const deleteNote = async (note: Note): Promise<void> => {
    const { notesDb } = useNotesDb()

    if (note.Id) {
      note.DeletedAt = new Date().toISOString()
      note.SyncStatus = SyncStatus.DeletedLocal
      await notesDb.put(note)
      try {
        await syncNote(note)
      } catch (error) {
        console.error('deleteNote: syncNote', error)
      }
    } else {
      // safe to delete from idb directly
      await notesDb.delete(note.LocalId)
    }
  }

  const fetchRemoteNotes = async (): Promise<void> => {
    const { notesDb } = useNotesDb()
    // const notes = await mockServerNotesApi()
    try {
      const notes = (
          (await notesApi.notesAndFilesGetNotes())?.data?.ListNoteSblApps || []
      ).map(crmNoteToNote)
      for (const note of notes) {
        const localNote = await notesDb.getById(note?.Id!)
        if (!localNote) {
          // new note from server
          note.LocalId = generateUUID()
          note.SyncStatus = SyncStatus.Synced
          note.CreatedAt = new Date().toISOString()
          note.ModifiedAt = undefined
          await notesDb.put(note)
          state.notesChangesFromServer++
        } else {
          if (localNote.ModifiedAt !== note.ModifiedAt) {
            note.LocalId = localNote.LocalId
            note.SyncStatus = SyncStatus.Synced
            await notesDb.put(note)
            state.notesChangesFromServer++
          }
        }
      }

      // delete notes that are not on server anymore (deleted from another device or CRM)
      const localServerIds = (await notesDb.getAll()).map((note) => note.Id).filter(notNil)
      const serverNotesIds = notes.map((note) => note.Id)
      const notesToDelete = localServerIds.filter(
          (id) => !serverNotesIds.includes(id)
      )

      for (const id of notesToDelete) {
        const note = await notesDb.getById(id)
        await notesDb.delete(note?.LocalId!)
        state.notesChangesFromServer++
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const syncNote = async (note: Note): Promise<void> => {
    const { notesDb } = useNotesDb()

    switch (note.SyncStatus) {
      case SyncStatus.CreatedLocal:
        const createdCrmNote = (
          await notesApi.notesAndFilesCreateNote(
            noteToCrmNote(note, currentUser.value.Id)
          )
        ).data?.NoteSblApp
        const newNote: Note = {
          ...crmNoteToNote(createdCrmNote),
          SyncStatus: SyncStatus.Synced,
          // preserve local id before storing in db
          LocalId: note.LocalId,
        }
        await notesDb.put(newNote)
        state.notesChangesFromServer++
        break
      case SyncStatus.UpdatedLocal:
        // const updatedNote = await mockServerNotesUpdateApi(note)
        const updatedCrmNote = (await notesApi.notesAndFilesUpdateNote(
          noteToCrmNote(note, currentUser.value.Id)
        )).data?.NoteSblApp
        const updatedNote = {
          ...crmNoteToNote(updatedCrmNote),
          SyncStatus: SyncStatus.Synced,
          LocalId: note.LocalId,
        }
        await notesDb.put(updatedNote)
        state.notesChangesFromServer++
        break
      case SyncStatus.DeletedLocal:
        // await mockServerNotesDeleteApi(note)
        await notesApi.notesAndFilesDeleteNote(note.Id)
        await notesDb.delete(note.LocalId)
        state.notesChangesFromServer++
        break
    }
  }

  const syncWithServer = async (): Promise<void> => {
    const { notesDb } = useNotesDb()

    // 1. handle updates from server
    await fetchRemoteNotes()

    // 2. push updates to server
    for (const note of await notesDb.getLocalChanges()) {
      await syncNote(note)
    }
  }

  syncWithServer()

  return {
    isNotesOpen,
    closeNotes,
    openNotes,
    toggleNotes,
    saveNote,
    deleteNote,
    notesChangesFromServer,
    syncWithServer,
    syncNote
  }
}

export const useNotes = () => {
  const { axiosClient } = useAxiosClient()
  return axiosWrapper(axiosClient.value)
}
