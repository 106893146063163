<template>
    <div class="w-full sm:w-72 flex flex-col cursor-pointer" tabindex="0" @click="$emit('click', $event)" @keydown="$emit('keydown', $event)">
      <GMCard class="h-68 pb-2 w-full sm:w-72 transition hover:shadow-2xl">
        <h2 class="whitespace-pre overflow-hidden w-full mt-2 font-semibold h-6">
          <GMIcon v-if="note.Pinned" name="star" class="inline w-5 h-5 mr-1 text-yellow-400" /> {{ note.Title }}
        </h2>
        <div class="w-full text-gray-500 h-4">{{ formatDate(note.CreatedAt) }}</div>
        <div class="w-full h-32 note-thumbnail-card">
          {{ note.Body }}
        </div>
        <div class="flex flex-wrap gap-x-4 gap-y-2 items-start h-12 pt-2 w-full">
          <CustomerNameBadge v-if="note.ForestOwnerId" :customer-id="note.ForestOwnerId" class="dark:bg-blue-gray-900" />
          <div v-if="note.PropertyName" class="text-sm bg-blue-100 dark:bg-blue-gray-900 rounded-full inline-flex items-center px-4 py-0">
            {{ note.PropertyName }}
          </div>
          <div v-if="note.ContractVsysId" class="text-sm bg-blue-100 dark:bg-blue-gray-900 rounded-full inline-flex items-center px-4 py-0">
            {{ note.ContractVsysId }}
          </div>
        </div>
      </GMCard>
    </div>
</template>
<script lang="ts">
import { GMCard, GMIcon } from '@gm/components'
import type {Note} from '~/models/note'
import formatDate from '../../helpers/formatDate'
import CustomerNameBadge from '~/components/Customers/CustomerNameBadge.vue'
import type {PropType} from "@vue/runtime-core";

export default defineComponent({
  methods: { formatDate },
  components: {
    GMCard,
    GMIcon,
    CustomerNameBadge,
  },
  props: {
    note: {
      type: Object as PropType<Note>,
      required: true
    }
  },
  setup() {
    return {

    }
  }
})
</script>

<style>
/* workaround for overflow-ellipsis on multiple lines */
/* only for webkit browsers */
.note-thumbnail-card {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-height: 1.6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}
</style>
