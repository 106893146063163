<template>
  <div>
    <!-- inline styles because we are opening this content in a new page with no style sheets -->
    <h1>{{ note.Title }}</h1>
    <div style="white-space: pre-line; margin-top: 2em;">{{ note.Body }}</div>
    <CustomerNameBadge :customer-id="note.ForestOwnerId" style="margin-top: 2em;" />
  </div>
</template>
<script lang="ts">
import type {Note} from '~/models/note'
import CustomerNameBadge from '~/components/Customers/CustomerNameBadge.vue'
import type {PropType} from "@vue/runtime-core";

export default defineComponent({
  components: { CustomerNameBadge },
  props: {
    note: {
      type: Object as PropType<Note>,
      required: true
    }
  },
  setup() {
    return {}
  }
})
</script>
