import { useDb } from '~/use/indexedDb/setup'
import {type Note, SyncStatus } from '~/models/note'
import cloneDeep from 'lodash/cloneDeep';

const { dbPromise } = useDb()

function notDeleted(note: Note) {
  return note.SyncStatus !== SyncStatus.DeletedLocal
}

const notesDb = {

  async getAll (): Promise<Note[]> {
    // get all not deleted
    const idbKeyRange = IDBKeyRange.bound(SyncStatus.Synced, SyncStatus.UpdatedLocal)
    try {
      return (await dbPromise).getAllFromIndex('notes', 'by-status', idbKeyRange)
    } catch (error) {
      console.error('notesDb: getAll', error)
    }
  },

  async getByContractId (contractId: string): Promise<Note[]> {
    if (!contractId) {
      return []
    }
    const idbKeyRange = IDBKeyRange.only(contractId)
    try {
      return (await dbPromise).getAllFromIndex('notes', 'by-contract', idbKeyRange)
        .then((notes) => notes.filter(notDeleted))
    } catch (error) {
      console.error('notesDb: getAll', error)
    }
  },

  async getByForestOwnerId (forestOwnerId: string): Promise<Note[]> {
    if (!forestOwnerId) {
      return []
    }
    const idbKeyRange = IDBKeyRange.only(forestOwnerId)
    try {
      return (await dbPromise).getAllFromIndex('notes', 'by-forest-owner', idbKeyRange)
        .then((notes) => notes.filter(notDeleted))
    } catch (error) {
      console.error('notesDb: getAll', error)
    }
  },

  async getByPropertyId (propertyId: string): Promise<Note[]> {
    if (!propertyId) {
      return []
    }
    const idbKeyRange = IDBKeyRange.only(propertyId)
    try {
      return (await dbPromise).getAllFromIndex('notes', 'by-property', idbKeyRange)
        .then((notes) => notes.filter(notDeleted))
    } catch (error) {
      console.error('notesDb: getAll', error)
    }
  },

  async getLocalChanges (): Promise<Note[]> {
    // get all notes that needs syncing to server
    const idbKeyRange = IDBKeyRange.bound(SyncStatus.CreatedLocal, SyncStatus.DeletedLocal)
    try {
      return (await dbPromise).getAllFromIndex('notes', 'by-status', idbKeyRange)
    } catch (error) {
      console.error('notesDb: getAll', error)
    }
  },

  async put<T> (value: Note): Promise<string> {
    try {
      return (await dbPromise).put('notes', cloneDeep(value))
    } catch (e) {
      console.error('notesDb: put', e)
    }
  },

  async getByLocalId (key: string): Promise<Note | undefined> {
    try {
      return (await dbPromise).get('notes', key)
    } catch (e) {
      console.error('notesDb: get', e)
    }
  },

  async delete (key: string): Promise<void> {
    try {
      return (await dbPromise).delete('notes', key)
    } catch (e) {
      console.error('notesDb: delete', e)
    }
  },

  async getById (key: string): Promise<Note | undefined> {
    try {
      return (await dbPromise).getFromIndex('notes', 'by-id', key)
    } catch (e) {
      console.error('notesDb: get', e)
    }
  },
}

export const useNotesDb = () => ({
  notesDb
})
