<template>
  <div
    role="combobox"
    aria-has-popup="listbox"
    :aria-owns="`ui-search_${uid}_listbox`"
    :aria-expanded="isSearchPanelOpen"
    class="relative"
  >
    <SearchField
      v-model="searchTerm"
      :active-item="activeItem"
      :listbox-id="`ui-search_${uid}_listbox`"
      :placeholder="$t('SEARCH_FORREST_OWNER')"
      :is-search-panel-open="isSearchPanelOpen"
      @keydown.native.enter="goToActive()"
      @keydown.native.ctrl.f="toggleFavourite()"
      @keydown.native.down.stop.prevent="onArrowMove(1)"
      @keydown.native.esc.stop="searchTerm = ''"
      @keydown.native.tab.stop="searchTerm = ''"
      @keydown.native.up.stop.prevent="onArrowMove(-1)"
      @active="toggleSearchPanel"
      :variant="variant"
      :class="{
        'bg-green-800 dark:bg-green-700 text-blue-gray-700 dark:text-blue-gray-300': variant === 'primary',
        'bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200': variant === 'secondary',
        'border': inline,
        'border-gray-200': inline,
        'dark:border-gray-700': inline,
      }"
    />
    <!-- <div
      v-if="isSearchPanelOpen"
      class="sr-only lg:not-sr-only fixed flex justify-center z-30 -m-1 py-1 px-2 text-xs left-0 right-0 top-14 dark:text-gray-300 tracking-wider bg-gray-200 dark:bg-gray-900 bg-opacity-80"
    >
      <div class="m-1">
        {{ $t('USE_ARROW_KEYS_TO_MOVE_UP_AND_DOWN') }}
      </div>
      <div class="m-1">
        {{ $t('PRESS_CONTROL_F_TO_ADD_REMOVE') }}
      </div>
    </div> -->
    <div
      v-if="isSearchPanelOpen"
      :id="`ui-search_${uid}_listbox`"
      ref="listboxWrapper"
      class="flex-1 left-0 overflow-y-auto bg-gray-50 dark:bg-gray-900 bg-opacity-80 dark:bg-opacity-80"
      :style="{
        maxHeight: inline ? undefined : 'calc(100vh - 3.5rem)',
      }"
      :class="{
        'mt-8': infoBannerIsEnabled,
        'transform': direction === 'top',
        '-translate-y-full': direction === 'top',
        '-top-6': direction === 'top',
        'top-14': direction === 'bottom',
        'fixed': !inline,
        'absolute': inline,
        'w-full': inline,
        'max-h-72': inline,
        'h-auto': inline,
        'h-full': !inline,
        'border': inline,
        'border-gray-200': inline,
        'dark:border-gray-700': inline,
        'rounded': inline,
      }"
    >
      <div
        ref="listboxContainer"
        class="flex-1 transition-all duration-300 w-screen z-10 lg:py-6"
        style="backdrop-filter: blur(4px)"
      >
        <div
          v-show="!noResult"
          class="max-w-4xl mx-auto"
        >
          <!-- FAVOURITES LIST -->
          <div v-if="customersFilteredFavourites.length > 0" class="pb-6">
            <div
              class="flex justify-between py-2 px-4 border-b border-gray-200 dark:border-gray-700 text-sm dark:text-gray-300 tracking-wider font-light"
              aria-live="polite"
              role="status"
            >
              {{ $t('FAVORITES') }}
              <GMOrb class="bg-gray-200 dark:bg-gray-700">
                {{ customersFilteredFavourites.length }}
              </GMOrb>
            </div>
            <div class="flex flex-col mt-6">
              <CustomerListItem
                v-for="customer in customersFilteredFavourites"
                :key="customer.Id"
                :active-item="activeItem"
                :is-first="isFirst(customer)"
                :is-last="isLast(customer)"
                :customer="customer"
                :listbox-id="`ui-search_${uid}_listbox`"
                :navigate-on-click="navigateOnClick"
                @select="onSelect"
              />
            </div>
          </div>

          <!-- NON FAVOURITES LIST -->
          <div v-if="customersFilteredNonFavourites.length > 0">
            <div
              class="absolute justify-between py-2 px-4 border-b border-gray-200 dark:border-gray-700 text-sm dark:text-gray-300 tracking-wider font-light"
              aria-live="polite"
              role="status"
            >
              {{ $t('FOREST_OWNERS') }}
              <GMOrb class="bg-gray-200 dark:bg-gray-700">
                {{ customersFilteredNonFavourites.length }}
              </GMOrb>
            </div>
            <div class="flex flex-col pt-18">
              <RecycleScroller
                v-slot="{ item }"
                page-mode
                :items="customersFilteredNonFavourites"
                :item-size="64"
                key-field="Id"
                :buffer="500"
              >
                <CustomerListItem
                  :active-item="activeItem"
                  :is-first="isFirst(item)"
                  :is-last="isLast(item)"
                  :customer="item"
                  :listbox-id="`ui-search_${uid}_listbox`"
                  :navigate-on-click="navigateOnClick"
                  @select="onSelect"
                />
              </RecycleScroller>
            </div>
          </div>
          <!-- <FavoriteList :search-term="searchTerm" :customers="customers" />
          <CustomerList :search-term="searchTerm" :customers="customers" /> -->
        </div>
        <div v-show="noResult" class="max-w-4xl mx-auto">
          {{ $t('NO_HITS') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">

import { GMOrb } from '@gm/components'
import { useCustomersDb } from '@/use/indexedDb/customersDb'
import { useCustomersStore } from '@/use/customers/store'
import { useDataStore } from '@/use/data/store'
import CustomerListItem from '@/components/new/customers/CustomerListItem.vue'
import SearchField from './SearchField.vue'
import type {Customer} from '~/models/customers'
import {generateUUID} from '~/helpers/util';
import {RecycleScroller} from 'vue-virtual-scroller/dist/vue-virtual-scroller.esm';
import type {PropType} from "@vue/runtime-core";
import type {Ref} from "@vue/reactivity";

export default defineComponent({
  name: 'UiSearch',
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    navigateOnClick: {
      type: Boolean,
      default: true
    },
    direction: {
      type: String as PropType<'bottom' | 'top'>,
      default: 'bottom'
    },
    variant: {
      type: String as PropType<'primary' | 'secondary'>,
      default: 'primary'
    },
    infoBannerIsEnabled: { // extra margin over search panel when open and top info banner is shown
      type: Boolean,
      default: false
    }
  },
  components: {
    CustomerListItem,
    GMOrb,
    SearchField,
    RecycleScroller,
  },
  setup (props, { emit }) {
    const { customersDb } = useCustomersDb()
    const {
      addFavorite,
      currentCustomer,
      favoriteIds,
      removeFavorite,
      searchTerm
    } = useCustomersStore()
    const { lastUpdated } = useDataStore()
    const isSearchPanelOpen = ref(false)
    const uid = generateUUID()

    const toggleSearchPanel = (bool: boolean) => {
      if (isSearchPanelOpen.value) {
        setTimeout(() => { isSearchPanelOpen.value = bool }, 150)
      } else {
        isSearchPanelOpen.value = bool
      }
    }

    const router = useRouter()

    const customers = ref([] as Customer[])
    const activeIndex: Ref<number> = ref(0)
    const listboxWrapper = ref()
    const listboxContainer = ref()


    const loadCustomers = async () => {
      customers.value = await customersDb.getByName()
    }

    // When we update the customer, also refetch the data for the search list
    // When the data is updated refetch customers
    watch([currentCustomer, lastUpdated], () => {
      loadCustomers()
    }, {
      immediate: true
    })

    // ALL CUSTOMERS
    const customersFiltered = computed<Customer[]>(() => {
      const value = searchTerm.value
      return customers.value
        .filter(
          (customer: Customer) =>
            !value ||
            customer.Name.toLowerCase().includes(value.toLowerCase())
        )
    })
    const noResult = computed(() => customersFiltered.value.length < 1)

    const onArrowMove = (change: number) => {
      let newIndex = activeIndex.value + change

      if ((newIndex + 1) > customersFiltered.value.length) {
        newIndex = 0
      } else if (newIndex < 0) {
        newIndex = customersFiltered.value.length - 1
      }
      activeIndex.value = newIndex
    }

    // FAVOURITES
    const customersFilteredFavourites = computed((): Customer[] =>
      customersFiltered.value ? customersFiltered.value.filter((customer: Customer) => favoriteIds.value.includes(customer.Id)) : []
    )

    // NON-FAVOURITES
    const customersFilteredNonFavourites = computed((): Customer[] =>
      customersFiltered.value ? customersFiltered.value.filter((customer: Customer) => !favoriteIds.value.includes(customer.Id)) : []
    )

    const activeItem = computed<Customer>(() => {
      let index = activeIndex.value
      const items = customersFiltered.value || []

      if (index < 0 || items.length === 0) { return null }
      if (index >= items.length) {
        index = items.length - 1
      }

      return items[index]
    })

    const getIndex = item => customersFiltered.value.findIndex(customer => customer.Id === item.Id)
    const isFirst = item => getIndex(item) === 0
    const isLast = item => getIndex(item) === (customersFiltered.value.length - 1)
    const isFirstIndex = computed<boolean>(() => activeIndex.value === 0)
    const isLastIndex = computed<boolean>(() => activeIndex.value === (customersFiltered.value.length - 1))

    watch(isLastIndex, () => {
      if (!isLastIndex.value) { return }
      const wrapper = listboxWrapper.value as HTMLElement
      const height = (listboxContainer.value as HTMLElement)?.getBoundingClientRect()?.height || 0
      wrapper?.scrollTo(0, height - (wrapper.getBoundingClientRect()?.height || 0))
    })

    watch(isFirstIndex, () => {
      if (!isFirstIndex.value) { return }
      const wrapper = listboxWrapper.value as HTMLElement
      wrapper?.scrollTo(0, 0)
    })

    const goToActive = (): void => {
      const customer = activeItem.value
      if (!customer) { return }
      if (props.navigateOnClick) {
        const path = customer.Properties.length === 1
          ? `/customers/${customer.Id}/${customer.Properties[0].Id}`
          : `/customers/${customer.Id}`

        router.push(path)
      } else {
        onSelect(customer)
      }
    }

    const toggleFavourite = () => {
      const activeCustomer = activeItem.value
      if (!favoriteIds.value.includes(activeCustomer.Id)) {
        addFavorite(activeCustomer.Id)
      } else {
        removeFavorite(activeCustomer.Id)
      }
    }

    const onSelect = (customer: Customer) => {
      emit('select', customer)
      isSearchPanelOpen.value = false
    }

    return {
      uid,
      activeIndex,
      activeItem,
      customers,
      customersFiltered,
      customersFilteredFavourites,
      customersFilteredNonFavourites,
      goToActive,
      isFirst,
      isLast,
      noResult,
      onArrowMove,
      isSearchPanelOpen,
      searchTerm,
      toggleFavourite,
      toggleSearchPanel,
      onSelect,
      listboxWrapper,
      listboxContainer,
    }
  }
})
</script>
